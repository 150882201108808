import { Component, computed, Signal } from '@angular/core';
import { SettingsLanguageComponent } from '../settings-language/settings-language.component';
import { StateService } from '../../../services/state.service';
import { SettingsTreatmentPlaceComponent } from '../settings-treatment-place/settings-treatment-place.component';
import { SettingsMedicalTrailComponent } from '../../../pages/settings/settings-medical-trail/settings-medical-trail.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, UpperCasePipe } from '@angular/common';
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonContent,
  IonItem,
  IonList,
  IonLabel,
  IonNavLink,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings-home',
  templateUrl: './settings-home.component.html',
  imports: [
    NgIf,
    UpperCasePipe,
    TranslateModule,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonContent,
    IonItem,
    IonList,
    IonLabel,
    IonNavLink,
  ],
})
export class SettingsHomeComponent {
  currentLanguage: Signal<string | undefined>;
  currentTreatmentPlace: Signal<string | undefined>;
  medicalTrail: Signal<string | undefined>;

  constructor(private stateService: StateService) {
    this.currentLanguage = stateService.currentLanguage;
    this.currentTreatmentPlace = stateService.currentTreatmentPlace;
    this.medicalTrail = computed(() => {
      const medicalTrail = this.stateService.medicalTrail();
      if (medicalTrail?.includes('enrolled-with-video')) {
        return 'enrolled-with-video';
      } else if (medicalTrail?.includes('enrolled-without-video')) {
        return 'enrolled-without-video';
      } else {
        return medicalTrail;
      }
    });
  }

  selectLanguagePage = SettingsLanguageComponent;
  selectTreatmentPlacePage = SettingsTreatmentPlaceComponent;
  selectMedicalTrailPage = SettingsMedicalTrailComponent;
}
