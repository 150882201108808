import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';

export class GlobalErrorHandler extends SentryErrorHandler {
  constructor(config: ErrorHandlerOptions | undefined) {
    const global = window ?? globalThis;
    // Hack that should ensure that GlobalErrorHandler works in zoneless.
    global?.addEventListener('error', (event) => {
      this.handleError(event.error);
      event.preventDefault();
    });
    global?.addEventListener('unhandledrejection', (event) => {
      this.handleError(event.reason);
      event.preventDefault();
    });
    super(config);
  }
}
