import '@angular/compiler';
import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import * as SentryAngular from '@sentry/angular';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { routes } from './app/app-routing';
import { bootstrapApplication } from '@angular/platform-browser';
import { StateService } from './app/services/state.service';
import { FirebaseAnalyticsService } from './app/services/firebase-analytics.service';
import { StorageService } from './app/services/storage.service';
import { provideIonicAngular, IonicRouteStrategy } from '@ionic/angular/standalone';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import * as Sentry from '@sentry/capacitor';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { isNoMonitorAndProd } from './app/helpers/check-for-monitor-and-prod.helper';
import { Capacitor } from '@capacitor/core';
import { GlobalErrorHandler } from './app/helpers/error-handler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

if (isNoMonitorAndProd() && injectSpeedInsights && !Capacitor.isNativePlatform()) {
  try {
    injectSpeedInsights({});
  } catch (error) {
    // do nothing
  }
}

if (environment.env !== 'development') {
  Sentry.init(
    {
      dsn: 'https://d9829813f6ca46bba3ff2e805c8c6db3@o492374.ingest.sentry.io/5559533',
      environment: environment.env,
      release: environment.release,
      dist: environment.dist,
      autoSessionTracking: true,
      integrations: [
        SentryAngular.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [window.location.origin, 'pregnantwithdiabetes.vercel.app'],
        }),
      ],
      tracePropagationTargets: ['localhost', 'pregnantwithdiabetes.vercel.app'],
      denyUrls: [/utm_source=monitoring/i],

      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [/Loading chunk 7005 failed/i],
    },
    SentryAngular.init,
  );
}

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './i18n/', '.json');

if (environment.production) {
  enableProdMode();
}

// reload page if error is "isProxied" and browser is Safari
if (window && navigator?.userAgent?.indexOf('Safari') > -1) {
  window.onunhandledrejection = (event) => {
    if (event?.reason?.message?.includes('isProxied')) {
      location.reload();
    }
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(
      IonicStorageModule.forRoot({
        name: '__pregnantdb',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      }),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useValue: new GlobalErrorHandler({ showDialog: false }),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    provideTranslateService({
      defaultLanguage: 'en',
    }),
    provideHttpClient(),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideAppInitializer(async () => {
      const storageService = inject(StorageService);
      const firebaseAnalyticsService = inject(FirebaseAnalyticsService);
      const stateService = inject(StateService);
      inject(SentryAngular.TraceService);
      await storageService.init();
      await firebaseAnalyticsService.init();
      await stateService.init();
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular(),
    provideRouter(routes),
  ],
  // eslint-disable-next-line no-console
}).catch((err) => console.error(err));
