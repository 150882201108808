import { Component, computed, Signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { StateService } from '../../services/state.service';
import { SafeHtmlPipe } from '../../services/safe-html.pipe';
import { addIcons } from 'ionicons';
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLinkWithHref,
} from '@ionic/angular/standalone';
import { NgIf } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics.service';

@Component({
  selector: 'app-article',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
  imports: [
    SafeHtmlPipe,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonContent,
    IonButton,
    IonIcon,
    RouterLink,
    NgIf,
    IonGrid,
    IonRow,
    IonCol,
    TranslatePipe,
    IonRouterLinkWithHref,
  ],
})
export class ArticlePageComponent {
  title: string | undefined = undefined;
  content: string = '';
  icon: string | undefined = undefined;
  treatmentPlace: Signal<string | undefined>;
  medicalTrail: Signal<string | undefined>;
  prevArticle: any | undefined = undefined;
  nextArticle: any | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
    private analyticsService: FirebaseAnalyticsService,
  ) {
    addIcons({ arrowBackOutline, arrowForwardOutline });
    this.medicalTrail = computed(() => {
      const medicalTrail = this.stateService.medicalTrail();
      if (medicalTrail?.includes('enrolled-with-video')) {
        return 'enrolled-with-video';
      } else if (medicalTrail?.includes('enrolled-without-video')) {
        return 'enrolled-without-video';
      } else {
        return medicalTrail;
      }
    });
    this.treatmentPlace = this.stateService.currentTreatmentPlace;

    this.setupArticleContent();
  }

  setupArticleContent() {
    const article = this.stateService.getArticle(this.route.snapshot.paramMap.get('slug') || '');
    if (!article) {
      this.router.navigate(['']);
      return;
    }
    const { menutitle, content, icon } = article;
    this.title = menutitle;
    this.content = content;
    this.icon = icon;
    const allArticles = this.stateService.allArticlesSorted();
    const currentIndex = allArticles.findIndex((allArticle) => allArticle.slug === article.slug);
    const prevArticle = currentIndex - 1 >= 0 ? allArticles.at(currentIndex - 1) : undefined;
    const nextArticle = allArticles.at(currentIndex + 1);
    this.prevArticle = prevArticle ? { slug: prevArticle.slug, title: prevArticle.menutitle } : undefined;
    this.nextArticle = nextArticle ? { slug: nextArticle.slug, title: nextArticle.menutitle } : undefined;
  }

  navLinkClick(direction: 'prev' | 'next') {
    const article = direction === 'next' ? this.nextArticle : this.prevArticle;
    this.analyticsService.trackEvent(`article_${direction}`, article);
  }
}
