import { Component, Signal } from '@angular/core';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { StateService } from '../../../services/state.service';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-settings-language',
  templateUrl: './settings-language.component.html',
  styles: `
    ion-radio::part(label) {
      display: block;
    }
  `,
  imports: [
    IonHeader,
    IonButtons,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonRadio,
    RouterLink,
    TranslateDirective,
    TranslatePipe,
  ],
})
export class SettingsLanguageComponent {
  currentLanguage: Signal<string | undefined>;
  constructor(private stateService: StateService) {
    this.currentLanguage = this.stateService.currentLanguage;
  }
  setLanguage(event: CustomEvent) {
    this.stateService.setCurrentLanguage(event.detail.value);
  }
}
