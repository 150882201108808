function storageCheckerHelper(): boolean {
  try {
    if (!window?.localStorage) {
      return false;
    }
    const storage = localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function indexDbCheckerHelper(): boolean {
  try {
    if (!window?.indexedDB) {
      return false;
    }
    const indexedDB = window.indexedDB;
    const x = '__indexedDB_test__';
    indexedDB.open(x);
    indexedDB.deleteDatabase(x);
    return true;
  } catch (e) {
    return false;
  }
}

export function combinedCheckerHelper(): boolean {
  return storageCheckerHelper() && indexDbCheckerHelper();
}
