import { Injectable } from '@angular/core';
import { InAppReview } from '@capacitor-community/in-app-review';
import { Platform } from '@ionic/angular/standalone';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class RateService {
  constructor(
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private platform: Platform,
  ) {}

  init() {
    setTimeout(async () => {
      await this.platform.ready();
      if (Capacitor.isNativePlatform()) {
        await InAppReview.requestReview();
        const platforms = this.platform.platforms().join('-');
        this.firebaseAnalyticsService.trackEvent('rating_popup', { platforms });
      }
    }, 10 * 1000);
  }
}
