import { Component, Signal } from '@angular/core';
import { StateService } from '../../../services/state.service';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { RouterLink } from '@angular/router';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-treatment-place',
  templateUrl: './settings-treatment-place.component.html',
  styles: `
    ion-radio::part(label) {
      display: block;
    }
  `,
  imports: [
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonBackButton,
    IonContent,
    IonList,
    IonItem,
    IonRadioGroup,
    IonRadio,
    RouterLink,
    TranslateDirective,
    TranslatePipe,
  ],
})
export class SettingsTreatmentPlaceComponent {
  currentTreatmentPlace: Signal<string | undefined>;

  constructor(private stateService: StateService) {
    this.currentTreatmentPlace = this.stateService.currentTreatmentPlace;
  }
  setTreatmentPlace(event: any) {
    this.stateService.setCurrentTreatmentPlace(event.detail.value);
  }
}
