import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { RouteEnum } from '../models/route-enum';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { WindowService } from './window.service';
import { SeoData } from '../models/seo-data';
import { Meta, Title } from '@angular/platform-browser';

const descriptionFormatter = (description: string) => {
  if (!description) {
    return '';
  }
  description = description.replace(/<\/?[^>]+(>|$)/g, '');
  return description.length < 160 ? description : `${description.substring(0, description.indexOf(' ', 160))}`;
};

@Injectable({
  providedIn: 'root',
})
export class SeoContentService {
  constructor(
    private stateService: StateService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private windowService: WindowService,
    private meta: Meta,
    private titleService: Title,
  ) {}

  async getTitle(route: RouteEnum, url: string): Promise<SeoData> {
    // this is needed to make sure we get the resolved language
    await firstValueFrom(this.translateService.get('MENU.HOME'));
    let title: string;
    let description = '';
    const imageUrl = this.translateService.currentLang.match(/da|sv/)
      ? '/assets/images/logo-gravidmeddiabetes.png'
      : '/assets/images/logo-pregnantwithdiabetes.png';
    switch (route) {
      case RouteEnum.Home:
        title = await firstValueFrom(this.translateService.get('MENU.HOME'));
        description = descriptionFormatter(
          this.stateService.getFrontPageIntro(this.translateService.currentLang) || '',
        );
        break;
      case RouteEnum.Settings:
        title = await firstValueFrom(this.translateService.get('MENU.SETTINGS'));
        break;
      case RouteEnum.Page:
        const page = this.stateService.getExtraPage(this.route.firstChild?.snapshot.paramMap.get('slug') || '');
        title = page?.menutitle || 'Diabetes';
        description = descriptionFormatter(page?.content || '');
        break;
      case RouteEnum.Article:
        const article = this.stateService.getArticle(this.route.firstChild?.snapshot.paramMap.get('slug') || '');
        title = article?.menutitle || 'Diabetes';
        description = descriptionFormatter(article?.content || '');
        break;
      default:
        title = 'Diabetes';
        break;
    }

    const appTitle = await firstValueFrom(this.translateService.get('APP.TITLE'));
    url = this.windowService.nativeWindow() ? this.windowService.nativeWindow().origin + url : url;
    const seoTitle = `${title} - ${appTitle}`;

    this.titleService.setTitle(seoTitle);

    // Standard Meta Tags
    this.meta.addTag({ name: 'description', content: description });

    // Open Graph Meta Tags
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:site_name', content: appTitle });
    this.meta.addTag({ property: 'og:description', content: description });
    this.meta.addTag({ property: 'og:image', content: imageUrl });
    this.meta.addTag({ property: 'og:url', content: url });

    // Twitter Card Meta Tags
    this.meta.addTag({ name: 'twitter:title', content: title });
    this.meta.addTag({ name: 'twitter:site', content: appTitle });
    this.meta.addTag({ name: 'twitter:description', content: description });
    this.meta.addTag({ name: 'twitter:image', content: imageUrl });
    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'twitter:image:alt', content: title });
    this.meta.addTag({ name: 'twitter:url', content: url });

    return {
      title,
      description,
      image: imageUrl,
      url,
    };
  }
}
