import { Component, computed, Signal } from '@angular/core';
import { StateService } from '../../services/state.service';
import { Article } from '../../models/article';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgOptimizedImage } from '@angular/common';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonToolbar,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonRouterLink,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-home',
  templateUrl: 'home-page.component.html',
  styleUrls: ['home-page.component.scss'],
  imports: [
    NgIf,
    NgFor,
    RouterLink,
    TranslateModule,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonContent,
    IonGrid,
    IonCol,
    IonRow,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonRouterLink,
    NgOptimizedImage,
  ],
})
export class HomePageComponent {
  articles: Signal<Article[]>;
  frontPageIntro: Signal<string>;

  constructor(private stateService: StateService) {
    this.articles = computed(() =>
      [...this.stateService.articles(), ...this.stateService.secretArticles()].sort((a, b) => a.order - b.order),
    );
    this.frontPageIntro = stateService.frontPageIntro;
  }
}
