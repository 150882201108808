<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="page-content">
    <div class="image-wrapper">
      <img
        class="page-image"
        *ngIf="'HOME.IMAGE' | translate"
        ngSrc="assets/images/{{ 'HOME.IMAGE' | translate }}.svg"
        alt="Logo"
        width="792"
        height="871"
        priority
      />
    </div>
    <span [innerHTML]="frontPageIntro()"></span>
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-md="6" size-xl="4" *ngFor="let article of articles()">
          <ion-card [routerDirection]="'forward'" [routerLink]="['/article/' + article.slug]">
            <ion-card-header>
              <ion-card-subtitle color="primary"
                >{{ article.menutitle }}<i class="{{ article.icon }} icon-large float-right"></i
              ></ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
