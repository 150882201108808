<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <span class="ion-text-uppercase">{{ title }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <i class="{{ icon }} header-icon"></i>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div
    class="page-content show-{{ treatmentPlace() }} show-{{ medicalTrail() }}"
    [innerHTML]="content | safeHtml"
  ></div>

  <ion-grid class="nav-links">
    <ion-row>
      <ion-col size="6">
        <a
          *ngIf="prevArticle"
          [routerLink]="['/article/', prevArticle.slug]"
          routerDirection="back"
          (click)="navLinkClick('prev')"
          class="prev"
        >
          <div class="direction-label ion-text-capitalize ion-text-start ion-padding-start">
            {{ 'ARTICLE.PREVIOUS' | translate }}
          </div>
          <ion-button fill="clear">
            <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
            {{ prevArticle.title }}
          </ion-button>
        </a>
      </ion-col>
      <ion-col size="6" class="flex ion-justify-content-end">
        <a
          *ngIf="nextArticle"
          [routerLink]="['/article/', nextArticle.slug]"
          routerDirection="forward"
          (click)="navLinkClick('next')"
          class="next"
        >
          <div class="direction-label ion-text-capitalize ion-text-end ion-padding-end">
            {{ 'ARTICLE.NEXT' | translate }}
          </div>
          <ion-button fill="clear">
            {{ nextArticle.title }}
            <ion-icon color="black" name="arrow-forward-outline" slot="end"></ion-icon>
          </ion-button>
        </a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
