<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'SETTINGS.SETTINGS' | translate }}</ion-title>
    <ion-buttons slot="end">
      <i class="icon-settings header-icon"></i>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-nav-link router-direction="forward" [component]="selectLanguagePage">
      <ion-item button detail="true">
        <ion-label>
          <h2>{{ 'SETTINGS.LANGUAGE' | translate }}</h2>
          <p>{{ 'SETTINGS.' + currentLanguage() | uppercase | translate }}</p>
        </ion-label>
      </ion-item>
    </ion-nav-link>
    <ion-nav-link *ngIf="currentLanguage() === 'da'" router-direction="forward" [component]="selectTreatmentPlacePage">
      <ion-item button detail="true">
        <ion-label>
          <h2>{{ 'SETTINGS.TREATMENT_PLACE' | translate }}</h2>
          <p>{{ 'SETTINGS.' + currentTreatmentPlace() | uppercase | translate }}</p>
        </ion-label>
      </ion-item>
    </ion-nav-link>
    <ion-nav-link
      *ngIf="currentLanguage() === 'da' || currentLanguage() === 'en'"
      router-direction="forward"
      [component]="selectMedicalTrailPage"
    >
      <ion-item button detail="true">
        <ion-label>
          <h2>{{ 'SETTINGS.MEDICAL_TRAIL.TITLE' | translate }}</h2>
          <p>{{ 'SETTINGS.MEDICAL_TRAIL.' + medicalTrail() | translate }}</p>
        </ion-label>
      </ion-item>
    </ion-nav-link>
  </ion-list>
</ion-content>
