import { computed, effect, Injectable, Signal, signal } from '@angular/core';
import { Article } from '../models/article';
import { Page } from '../models/page';
import { AppState } from '../models/app-state';
import { StorageService } from './storage.service';
import initialArticleData from '../initial-data/article.data';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { environment } from '../../environments/environment';
import { MedicalTrail } from 'src/app/models/medical-trail';

const APP_STATE_KEY = 'app-state-v2';
const DEFAULT_TREATMENT_PLACE = 'andet';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  #firstRun = true;
  constructor(
    private storageService: StorageService,
    private apiService: ApiService,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {
    // skip the first initialization of the state - after that always save the state to storage
    effect(() => {
      if (this.#firstRun && this.#state()) {
        this.#firstRun = false;
        return;
      }
      this.storageService.set(APP_STATE_KEY, this.#state());
    });
    // set the language for the translation service
    effect(() => {
      const language = this.currentLanguage();
      if (language) {
        this.translate.use(language);
      }
    });
    effect(() => {
      if (environment.env === 'development') {
        // log the state on updates
        console.log(this.#state()); // eslint-disable-line no-console
      }
    });
  }

  async init() {
    const storageState = await this.storageService.get(APP_STATE_KEY);
    if (storageState) {
      this.setState(storageState);
    } else {
      this.setCurrentLanguage(this.languageService.getSystemLanguage());
    }

    this.apiService.getAppContent$().subscribe((state) => {
      this.setState({ ...this.#state, ...state });
    });
  }

  #state = signal<AppState>({
    ...initialArticleData,
    treatmentPlace: DEFAULT_TREATMENT_PLACE,
    currentLanguage: this.languageService.getSystemLanguage(),
    medicalTrail: 'not-enrolled',
  });

  state = this.#state.asReadonly();
  articles: Signal<Array<Article>> = computed(() =>
    this.#state().articles.filter((article) => this.getCurrentLanguage() === article.language),
  );
  secretArticles: Signal<Array<Article>> = computed(() =>
    this.#state().articles.filter(
      (article) =>
        'secret-' + this.getCurrentLanguage() === article.secretLanguage &&
        (this.getMedicalTrail()?.includes('enrolled-with-video') ||
          this.getMedicalTrail()?.includes('testing-with-video')),
    ),
  );
  allArticlesSorted: Signal<Array<Article>> = computed(() =>
    [...this.articles(), ...this.secretArticles()].sort((a, b) => a.order - b.order),
  );
  pages: Signal<Array<Page>> = computed(() =>
    this.#state().pages.filter((page) => this.getCurrentLanguage() === page.language),
  );
  frontPageIntro: Signal<string> = computed(
    () =>
      this.#state().frontPageIntros.find((frontPageIntro) => this.getCurrentLanguage() === frontPageIntro.language)
        ?.content ?? '',
  );
  currentLanguage: Signal<string | undefined> = computed(() => this.#state().currentLanguage);
  currentTreatmentPlace: Signal<string | undefined> = computed(() => this.#state().treatmentPlace);
  medicalTrail: Signal<MedicalTrail | undefined> = computed(() => this.#state().medicalTrail);

  setState(state: AppState | undefined): void {
    if (!state) {
      return;
    }
    const newest = state.articles.reduce((prev, current) => (prev.lastupdated > current.lastupdated ? prev : current));
    const older = this.#state().articles.reduce(
      (prev, current) => (prev.lastupdated > current.lastupdated ? prev : current),
      {
        lastupdated: 0,
      },
    );
    if (newest.lastupdated > older.lastupdated) {
      this.#state.update((prev) => ({ ...prev, ...state }));
    } else {
      this.#state.update((prev) => ({
        ...prev,
        treatmentPlace: state.treatmentPlace ?? prev.treatmentPlace,
        currentLanguage: state.currentLanguage ?? prev.currentLanguage,
        medicalTrail: state.medicalTrail ?? prev.medicalTrail,
      }));
    }
  }

  setCurrentLanguage(currentLanguage: string): void {
    this.#state.update((prev) => ({ ...prev, currentLanguage }));
  }

  getCurrentLanguage(): string | undefined {
    return this.#state().currentLanguage;
  }

  setCurrentTreatmentPlace(treatmentPlace: string): void {
    this.#state.update((prev) => ({ ...prev, treatmentPlace }));
  }

  getArticle(slug: string): Article | undefined {
    return this.#state().articles.find((article) => article.slug === slug);
  }

  getExtraPage(slug: string): Article | undefined {
    return this.#state().pages.find((page) => page.slug === slug);
  }

  getFrontPageIntro(language: string): string | undefined {
    return this.#state().frontPageIntros.find((intro) => intro.language === language)?.content;
  }

  setMedicalTrail(medicalTrail: MedicalTrail): void {
    this.#state.update((prev) => ({ ...prev, medicalTrail }));
  }

  getMedicalTrail(): MedicalTrail | undefined {
    return this.#state().medicalTrail;
  }
}
