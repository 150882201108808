import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { combinedCheckerHelper } from '../helpers/storage-checker.helper';
import { captureException } from '../helpers/error-capture.helper';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  #storage: Storage | null = null;

  constructor(private storage: Storage) {}

  async init() {
    try {
      if (!combinedCheckerHelper()) {
        return;
      }
      this.#storage = await this.storage.create();
    } catch (error) {
      captureException(error, 'info', 'storage-service-init');
    }
  }

  async set(key: string, value: any): Promise<any> {
    if (!this.#storage) {
      return Promise.resolve(undefined);
    }
    return this.#storage?.set(key, JSON.stringify(value));
  }

  async get(key: string): Promise<any> {
    if (!this.#storage) {
      return Promise.resolve(undefined);
    }
    const value = await this.#storage.get(key);
    return value ? JSON.parse(value) : undefined;
  }
}
