import { Injectable } from '@angular/core';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { captureException } from '../helpers/error-capture.helper';

declare let YT: any;

@Injectable({
  providedIn: 'root',
})
export class YoutubeService {
  counter = 0;
  constructor(private firebaseAnalyticsService: FirebaseAnalyticsService) {}

  init(): void {
    if (typeof YT === 'undefined') {
      this.initYoutube();
    } else if (typeof YT.Player === 'undefined') {
      this.counter++;
      if (this.counter > 5) {
        return;
      }
      setTimeout(() => {
        this.init();
      }, 1000);
    } else {
      this.startYoutubeTracking();
    }
  }

  private initYoutube() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    tag.onload = this.startYoutubeTracking.bind(this);
    firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
  }

  startYoutubeTracking() {
    const that = this;
    document.querySelectorAll('.youtube-player').forEach(function (item) {
      try {
        if (!(YT && YT.Player)) {
          return;
        }
        let playerStarted = false;

        new YT.Player(item, {
          events: {
            onStateChange: function onPlayerStateChange(state: any) {
              if (state.data === YT.PlayerState.PLAYING && !playerStarted) {
                // only track play event once pr session
                that.firebaseAnalyticsService.trackEvent('youtube_play', {
                  action: 'play',
                  video: state?.target?.videoTitle,
                });
                playerStarted = true;
              }
              if (state.data === YT.PlayerState.ENDED) {
                that.firebaseAnalyticsService.trackEvent('youtube_ended', {
                  action: 'ended',
                  video: state?.target?.videoTitle,
                });
              }
            },
          },
        });
      } catch (error) {
        captureException(error, 'info', 'youtube-player-init');
      }
    }, that);
  }
}
